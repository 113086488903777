<template>
  <div
    class="w-100 h-100 px-6 py-8 d-flex flex-column justify-content-between bg-white rounded-lg"
  >
    <div>
      <div class="w-100 symbol symbol-150 d-flex justify-center">
        <img class="w-100" :src="connector.image" alt="item image" />
      </div>

      <h5 class="my-8 text-dark-50 font-weight-bold">
        {{ connector.name }}
      </h5>
    </div>

    <div class="w-100 d-flex align-center justify-space-between">
      <button
        class="btn btn-sm btn-info px-5 py-3 ls1"
        @click="
          () =>
            connector.is_active
              ? ($refs.titleValueListModal.dialog = true)
              : $refs.actionsDialog.toggleModal()
        "
      >
        {{ connector.is_active ? $t("see-details") : $t("connect") }}
      </button>

      <div class="d-flex align-center">
        <div
          class="rounded-circle h-10px w-10px"
          :class="[connector.is_active ? 'bg-success' : 'bg-danger']"
        ></div>

        <span class="mx-2 mb-0 text-dark-50 font-weight-bold">
          {{ connector.is_active ? $t("active") : $t("inactive") }}
        </span>
      </div>
    </div>

    <ActionsDialog
      ref="actionsDialog"
      :connector_id="connector.id"
      :customer_id="customerId"
    />

    <TitleValueListModal
      ref="titleValueListModal"
      :data="connector.credentials"
      :triggerActionDialogForEdit="
        () => {
          $refs.actionsDialog.toggleModal(
            connector.credentials,
            connector.code
          );
          $refs.titleValueListModal.dialog = false;
        }
      "
    />
  </div>
</template>

<script>
import ActionsDialog from "@/own/components/warehouseExecutionSystem/connectors/ActionsDialog";
import TitleValueListModal from "@/own/components/warehouseExecutionSystem/connectors/TitleValueListModal";

export default {
  name: "ConnectorCard",
  components: { ActionsDialog, TitleValueListModal },
  props: {
    connector: { required: true, type: Object },
    customerId: { required: true, type: Number },
  },
};
</script>
