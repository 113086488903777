<template>
  <div class="p-4 poppins">
    <div class="custom-content-height">
      <div class="container-fluid mb-8">
        <div class="row justify-content-between">
          <div
            class="col-12 col-md-auto ml-lg-4"
            :class="{ 'text-right': $vuetify.rtl }"
          >
            <h3 class="font-weight-bolder mb-1">{{ $t("connectors") }}</h3>
            <p class="text-dark-50 font-weight-bold">
              {{ $t("wes-connectors") }}
            </p>
          </div>
        </div>
      </div>

      <div class="container-fluid" v-if="customers.length">
        <div
          v-for="(item, index) in customers"
          :key="index"
          class="w-100 mb-8 px-1"
        >
          <div class="d-flex align-center mb-4">
            <h3 class="mx-2 mb-0">{{ item.customer_name }}</h3>
            <p class="text-dark-50 mb-0">
              {{ item.organization_name }}
            </p>
          </div>

          <div class="row px-3 px-xl-6" v-if="item.connectors.length">
            <div
              v-for="(connector, itemIndex) in item.connectors"
              :key="itemIndex"
              class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-4 px-4"
            >
              <ConnectorCard
                :connector="connector"
                :customerId="item.customer_id"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GET_WES_CUSTOMERS } from "@/core/services/store/wes.module";
import ConnectorCard from "@/own/components/warehouseExecutionSystem/connectors/ConnectorCard";
// import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

export default {
  name: "Connectors",
  components: { ConnectorCard },
  mounted() {
    this.$store.dispatch(GET_WES_CUSTOMERS);
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    customers: function () {
      return this.$store.getters.getWESCustomers
        ? this.$store.getters.getWESCustomers
        : [];
    },
    isClient: function () {
      return !this.$store.getters.currentUser.data.is_client;
    },
  },
  // methods: {
  //   async updateTableContent() {
  //     let state = {
  //       ...this.$store.getters[this.store_names.getTableState],
  //     };
  //     this.$store.commit(SET_PAGE_LOADING, true);
  //     await this.$store.dispatch(this.table_state.UPDATE_TABLE_DATA, state);
  //
  //     this.$forceUpdate();
  //     this.$store.commit(SET_PAGE_LOADING, false);
  //   },
  // },
};
</script>
